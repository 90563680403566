/* eslint-disable max-len */

import { DeleteOutline } from '@mui/icons-material';
import {
    Box,
    Button,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getDataFromOrder } from 'entities/Order';
import { getOrdersSearchParamsStatus } from 'entities/Order/model/selectors/ordersSelectors';
import { getSelectedPropertyId } from 'entities/Property/model/selectors/PropertySelectors';
import { getUserCanSeePrices } from 'entities/User/model/selectors/userPermissionsSeletors';
import { getRouteOrdersView } from 'shared/const/router';
import { getPrice } from 'shared/lib/getPrice/getPrice';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { useFeatureFlag } from 'shared/lib/hooks/useFeatureFlag';
import RemoveOrderModal from 'shared/ui/RemoveOrderModal/RemoveOrderModal';

import {
    getOrders,
    getOrdersCurrentPage,
    getOrdersListIsLoading,
    getOrdersPageSize,
    getOrdersTableSortingColumnName,
    getOrdersTableSortingDirection,
    getOrdersTableTotal,
    orderTableIsInitialLoaded,
} from '../model/selectors/catalogItemSelectors';
import { deleteOrder } from '../model/services/deleteOrder/deleteOrder';
import { fetchOrdersCounters } from '../model/services/fetchOrdersCounters/fetchOrdersCounters';
import { orderTableActions } from '../model/slices/OrderTableSlice';

export const OrdersTableGrid = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const renovationEnabled = useFeatureFlag('renovation_enabled');
    const orders = useSelector(getOrders);
    const isOrdersLoading = useSelector(getOrdersListIsLoading);
    const isInitialLoaded = useSelector(orderTableIsInitialLoaded);
    const page = useSelector(getOrdersCurrentPage);
    const rowsPerPage = useSelector(getOrdersPageSize);
    const total = useSelector(getOrdersTableTotal);
    const sortingColumnName = useSelector(getOrdersTableSortingColumnName);
    const sortingDirection = useSelector(getOrdersTableSortingDirection);
    const canSeePrices = useSelector(getUserCanSeePrices);
    const currentStatus = useSelector(getOrdersSearchParamsStatus);

    const isLoading = !(!isOrdersLoading && isInitialLoaded);
    const isDraftTab =
        orders[0]?.status === 'DRAFT' ||
        (!isInitialLoaded && currentStatus === 'DRAFT') ||
        (isInitialLoaded && orders?.length === 0 && currentStatus === 'DRAFT');

    const [isShowDialog, setIsShowDialog] = useState(false);
    const [isLoadingDeleteOrder, setIsLoadingDeleteOrder] = useState(false);
    const [idOfActiveDeleteButton, setIdOfActiveDeleteButton] = useState('');
    const [orderNumberToDelete, setOrderNumberToDelete] = useState('');
    const selectedPropertyId = useSelector(getSelectedPropertyId);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        dispatch(orderTableActions.setCurrentPage(newPage));
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        dispatch(
            orderTableActions.setPageSize(parseInt(event.target.value, 10)),
        );
        dispatch(orderTableActions.setCurrentPage(0));
    };

    const openOrder = useCallback(
        (id: string) => () => {
            navigate(getRouteOrdersView(id));
        },
        [navigate],
    );

    const onSort = (column: string) => {
        if (isLoading) {
            return;
        }

        if (column === sortingColumnName) {
            dispatch(
                orderTableActions.setSortingDirection(
                    sortingDirection === 'desc' ? 'asc' : 'desc',
                ),
            );
        } else {
            dispatch(orderTableActions.setSortingDirection('asc'));
        }
        dispatch(orderTableActions.setSortingColumnName(column));
    };

    const tableHeaderData = useMemo(
        () => [
            { label: t('ID'), value: 'order_number', show: true, width: 150 },
            { label: t('Type'), value: 'order_type', show: renovationEnabled },
            {
                label: t('Created'),
                value: 'created_at',
                show: true,
                width: 135,
            },
            {
                label: t('Created By'),
                value: 'creator__first_name,creator__last_name',
                show: true,
            },
            { label: t('Updated At'), value: 'updated_at', show: true },
            {
                label: t('Updated By'),
                value: 'last_updated_by__first_name,last_updated_by__last_name',
                show: true,
            },
            { label: t('Total Items'), value: 'items_count', show: true },
            {
                label: t('Total'),
                value: 'total_price',
                show: canSeePrices,
            },
            {
                label: '',
                value: '',
                show: isDraftTab,
                width: 110,
            },
        ],
        [t, canSeePrices, isDraftTab, renovationEnabled],
    );

    const closeDialog = useCallback(() => {
        setIsShowDialog(false);
    }, []);

    const openDialog = useCallback((id: string, orderNumber: string) => {
        setIsShowDialog(true);
        setIdOfActiveDeleteButton(id);
        setOrderNumberToDelete(orderNumber);
    }, []);

    const deleteOrderFromList = useCallback(async () => {
        setIsLoadingDeleteOrder(true);
        await dispatch(deleteOrder(idOfActiveDeleteButton));
        toast(t('The order has been deleted'));
        setIsLoadingDeleteOrder(false);
        setIsShowDialog(false);

        await dispatch(fetchOrdersCounters(selectedPropertyId));
    }, [dispatch, idOfActiveDeleteButton, selectedPropertyId, t]);

    return (
        <>
            <TableContainer>
                <Table
                    data-testid="orders-table"
                    sx={{
                        '& td': {
                            padding: '16px 12px',
                            borderBottom: 'none',
                        },
                        '& th': {
                            padding: '16px 12px',
                        },
                    }}
                >
                    <TableHead>
                        <TableRow>
                            {tableHeaderData.map((item) => {
                                if (item.show)
                                    return (
                                        <TableCell
                                            width={item.width || undefined}
                                            sx={{ cursor: 'pointer' }}
                                            key={item.value}
                                            onClick={() => onSort(item.value)}
                                        >
                                            <TableSortLabel
                                                sx={{
                                                    width: '100%',
                                                    display: 'block',
                                                }}
                                                active={
                                                    sortingColumnName ===
                                                    item.value
                                                }
                                                direction={
                                                    sortingColumnName ===
                                                    item.value
                                                        ? sortingDirection
                                                        : 'asc'
                                                }
                                            >
                                                {item.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!isLoading
                            ? orders?.map((order) => {
                                  const {
                                      id,
                                      orderType,
                                      orderNumber,
                                      creatorName,
                                      createdAtDay,
                                      createdAtTime,
                                      updatedAtDay,
                                      updatedAtTime,
                                      itemsCount,
                                      totalPrice,
                                      lastUpdatedBy,
                                  } = getDataFromOrder(order);

                                  return (
                                      <TableRow
                                          data-testclass="order-row"
                                          key={id}
                                          onClick={openOrder(id)}
                                          sx={{
                                              cursor: 'pointer',
                                              '&:hover': {
                                                  backgroundColor: '#E2E9F4',
                                              },
                                          }}
                                      >
                                          <TableCell data-testclass="number">
                                              <Typography
                                                  typography={
                                                      'openSans.body2Medium'
                                                  }
                                              >
                                                  {orderNumber}
                                              </Typography>
                                          </TableCell>
                                          {renovationEnabled && (
                                              <TableCell data-testclass="type">
                                                  <Typography
                                                      typography={
                                                          'openSans.body2'
                                                      }
                                                  >
                                                      {orderType}
                                                  </Typography>
                                              </TableCell>
                                          )}
                                          <TableCell data-testclass="created_at">
                                              <Typography
                                                  typography={'openSans.body2'}
                                              >
                                                  {createdAtDay}
                                              </Typography>
                                              <Typography
                                                  typography={
                                                      'openSans.caption'
                                                  }
                                              >
                                                  {createdAtTime}
                                              </Typography>
                                          </TableCell>

                                          <TableCell data-testclass="created_by">
                                              <Typography
                                                  typography={'openSans.body2'}
                                              >
                                                  {creatorName}
                                              </Typography>
                                          </TableCell>
                                          <TableCell data-testclass="updated_at">
                                              <Typography
                                                  typography={'openSans.body2'}
                                              >
                                                  {updatedAtDay}
                                              </Typography>
                                              <Typography
                                                  typography={
                                                      'openSans.caption'
                                                  }
                                              >
                                                  {updatedAtTime}
                                              </Typography>
                                          </TableCell>
                                          <TableCell data-testclass="updated_by">
                                              <Typography
                                                  typography={'openSans.body2'}
                                              >
                                                  {lastUpdatedBy}
                                              </Typography>
                                          </TableCell>
                                          <TableCell data-testclass="total_items">
                                              <Typography
                                                  typography={'openSans.body2'}
                                              >
                                                  {itemsCount}
                                              </Typography>
                                          </TableCell>
                                          {canSeePrices && (
                                              <TableCell data-testclass="total_price">
                                                  {getPrice(totalPrice)}
                                              </TableCell>
                                          )}
                                          {isDraftTab && (
                                              <TableCell
                                                  align="center"
                                                  data-testclass="actions"
                                              >
                                                  <Button
                                                      data-testclass="btn-order-delete"
                                                      sx={{
                                                          '& span': {
                                                              margin: 0,
                                                          },
                                                          padding: '5px',
                                                          minWidth: 'auto',
                                                      }}
                                                      startIcon={
                                                          <DeleteOutline
                                                              color={
                                                                  idOfActiveDeleteButton ===
                                                                      id &&
                                                                  isShowDialog
                                                                      ? 'primary'
                                                                      : 'disabled'
                                                              }
                                                              sx={{
                                                                  fontSize:
                                                                      '25px !important',
                                                              }}
                                                          />
                                                      }
                                                      onClick={(e) => {
                                                          e.stopPropagation();
                                                          openDialog(
                                                              id,
                                                              orderNumber,
                                                          );
                                                      }}
                                                  />
                                              </TableCell>
                                          )}
                                      </TableRow>
                                  );
                              })
                            : null}
                    </TableBody>
                </Table>
            </TableContainer>
            {isLoading && (
                <Box display="flex" flexDirection="column" gap="2px">
                    {Array.from({ length: rowsPerPage }).map((item, index) => (
                        <Skeleton
                            key={index}
                            variant="rectangular"
                            height="70px"
                        />
                    ))}
                </Box>
            )}
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                aria-disabled={isLoading}
            />
            <RemoveOrderModal
                isShowDeleteOrder={isShowDialog}
                title={t('Delete Order')}
                deleteButtonText={`Delete order`}
                text={`Are you sure you want to delete order <strong>${orderNumberToDelete}</strong>? This action cannot be undone.`}
                isLoadingDeleteOrder={isLoadingDeleteOrder}
                onCloseDeleteOrder={closeDialog}
                onSubmitDeleteOrder={deleteOrderFromList}
            />
        </>
    );
};
