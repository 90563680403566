import { TextField } from '@mui/material';
import { useField } from 'formik';
import { ChangeEvent, FocusEventHandler } from 'react';

interface FormikInputProps {
    name: string;
    label: string;
    placeholder?: string;
    disabled?: boolean;
    hasError?: boolean;
    readOnly?: boolean;
    loading?: boolean;
    minimized?: boolean;
    maxLength?: number;
    validateOnTouch?: boolean;
    formatAction?: (value: string) => string;
    onBlurAction?: () => void;
}

const minimizedStyle = {
    '& .MuiInputBase-root': {
        height: '40px',
        position: 'relative',
    },
    '& label': {
        top: '-8px',
    },
    '& .MuiInputBase-root input': {
        width: 'calc(100% - 48px)',
    },
};

const FormikInput = ({
    name,
    label,
    placeholder,
    disabled,
    readOnly,
    hasError,
    loading,
    minimized,
    maxLength,
    validateOnTouch = true,
    formatAction,
    onBlurAction,
}: FormikInputProps) => {
    const [field, { touched, error }] = useField(name);

    const handleChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        // If there is an optional format method passed in,
        // format the string as it changes and modify the value before it gets processed by Formik
        if (formatAction) {
            let value = event.target.value;
            value = formatAction(value);
            event.target.value = value;
        }

        field.onChange(event);
    };

    const blur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
        e,
    ) => {
        if (onBlurAction) {
            onBlurAction();
        }
        field.onBlur(e);
    };

    // Note regarding `validateOnTouch`, as part of working with Blake on forms, we are going to change how form validation presents
    // I added `validateOnTouch` defaulting to true to not impact older areas of the code until we update them in their respective tickets
    // `validateOnTouch` will disappear shortly with that in mind
    return (
        <TextField
            data-testid={`Formik-input-${name}`}
            sx={minimized ? minimizedStyle : {}}
            id={name}
            disabled={loading || disabled}
            label={label}
            placeholder={placeholder}
            variant="outlined"
            fullWidth
            value={field.value || ''}
            onChange={handleChange}
            onBlur={blur}
            error={(validateOnTouch && touched && Boolean(error)) || hasError}
            helperText={(validateOnTouch && touched) || hasError ? error : ''}
            inputProps={{
                maxLength: maxLength || '',
                readOnly,
            }}
        />
    );
};

export default FormikInput;
